import logo from './logo.svg';
import './App.css';

import * as Easing from './easing'

import {Scene} from './Scene'
import PageRenderer from './PageRenderer';
//import Canvas from './Canvas';
//import DrawPlant from './Plant.js';

import ReactDOM from 'react-dom'
import React, {useState, useEffect, useRef, useCallback, useContext, createContext, memo} from 'react'

import AppBar from '@mui/material/AppBar';
import {default as BoxMUI} from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
//import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';

import Zdog from 'zdog';
import { Illustration, Shape, Anchor, Hemisphere, Cone, Box, useZdog, useRender, useInvalidate} from "react-zdog";

import Zfont from 'zfont';
import freedom from './assets/Freedom-10eM.ttf'

import { createBrowserRouter, createRoutesFromElements,
	 Route, RouterProvider, useLocation, useNavigate } from "react-router-dom"

export const RenderContext = createContext(null);

//NOTE: Helpful debugging tool
//https://stackoverflow.com/questions/41004631/trace-why-a-react-component-is-re-rendering
export function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

//Tip from one of the cult leaders: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
//We can use this for any timed behaviors 'callback' with 'delay' interval
export function useInterval(callback, delay) {
  const savedCallback = useRef();
 
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
 
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const DragControl = () => {
  const invalidate = useInvalidate();
  const state = useZdog();

    //I guess this just invalidates everytime we get a drag, which then gets us the context to rotate the whole scene and re render
  useEffect(() => {
    if (state.illu) { 
      state.illu.onDragMove = function () {
        invalidate();
      };
    }
  }, [state, invalidate]);

  return <></>;
};

function App() {

    Zfont.init(Zdog);
    
    //NOTE: This forces a re-render when the window is resized, assuming that the contianing canvas will also resize.
    const [canvasSize, setCanvasSize] = useState(() => {return(new Zdog.Vector({x: window.innerWidth, y: window.innerHeight}))});
    const setCanvas = () => {
	setCanvasSize({x: canvas.current.width, y:canvas.current.height})
    }

    //Need to enforce the 16:9 aspect ratio, and scale the y components to accomodate?
    //Mobile portrait mode might be screwed - ok with that for now.
    let dev_x = 1920, dev_y = 971;
    let locked_aspect = 16/9;
    let screen_aspect = canvasSize.x / canvasSize.y;
    let scale_y = canvasSize.y / dev_y; //We have to rescale x coordinates with this too? All coords?
    
    const prevZoom = useRef(1);
    const nextZoom = useRef(1);

    const prevTarget = useRef(new Zdog.Vector());
    const nextTarget = useRef(new Zdog.Vector());
    let plantOffset = useRef(new Zdog.Vector());

    const [renderState, setRenderState] = useState(() => { return({
	render: true, //NOTE: Sets whether we are doing on-demand rendering with zDog or freezing the canvas for other stuff
	isZooming: false,
	illuZoom: 1,
	interpolator: 0,
        page: "",
	prevPage: "",
        pageUpdate: false });
    });

    const updatePageState = useCallback((target_page, manual_update) => {
	setRenderState((prevState) => {
		return({
		    ...prevState,
		    isZooming: true,
		    interpolator: 0,
		    page: target_page,
		    prevPage: prevState.page,
		    pageUpdate: manual_update });
	});
    }, [renderState]);
    
    const researchPageClick = () => {
	updatePageState("Research", true);
    }
    const softwarePageClick = () => {
	updatePageState("Software", true);
    }
    const gamesPageClick = () => {
	updatePageState("Games", true);
    }
    
    const pageInterpolator = (target_zoom, zoom_target_offset, target_page) => {
	prevZoom.current = nextZoom.current;
	nextZoom.current = target_zoom;//*scale_y;
	//TODO: Need to do a mult if !renderState.render, but relative to the last screen coordinates. Haven't dont his anywhere yet.
        prevTarget.current = nextTarget.current;
	nextTarget.current = zoom_target_offset;//.multiply({x: scale_x, y: scale_y});
    }
    const pageInterpolatorCallback = useCallback(pageInterpolator, [canvasSize]);
    
    if(renderState.isZooming)
    {
	if(renderState.interpolator >= 1)
	{
	    //NOTE: Flip render state and stop zooming
	    setRenderState((prevState) => {
		return({
		    ...prevState,
		    render: !prevState.render,
		    isZooming: false,
		    pageUpdate: false });
	    });
	}
    }

    //Rework and simplify the interpolation before trying to manage page state again.
    //If possible, try to delete the hook on Plant that reacts to  page state. I don't think it's
    //possible though.
    let delay = 10;
    const zoomCallback = () => {
	let zoom_speed = 0.00125;
	let timer_interpolator = renderState.interpolator + (zoom_speed * delay);
	timer_interpolator = timer_interpolator > 1 ? 1 : timer_interpolator;
	
	let eased_zoom_interpolator = Easing.easeOutExpo(timer_interpolator);
	let illu_zoom = renderState.illuZoom;
	if(!renderState.render)
	{
	    //We'll need another case for going between pages linearly (page != "")
	    illu_zoom = Zdog.lerp(prevZoom.current, nextZoom.current, eased_zoom_interpolator)

	    eased_zoom_interpolator = Easing.easeInOutExpo(timer_interpolator);
	    var lerp_target = prevTarget.current.copy().lerp(nextTarget.current, eased_zoom_interpolator);
	    plantOffset.current.set(lerp_target);
	}
	else
	{
	    eased_zoom_interpolator = Easing.easeInExpo(timer_interpolator);
	    illu_zoom = Zdog.lerp(prevZoom.current, nextZoom.current, eased_zoom_interpolator)
	    
	    eased_zoom_interpolator = Easing.easeOutExpo(timer_interpolator);
	    var lerp_target = prevTarget.current.copy().lerp(nextTarget.current, eased_zoom_interpolator);
	    plantOffset.current.set(lerp_target);
	}

	setRenderState((prevState) => {
		return({
		    ...prevState,
		    illuZoom: illu_zoom,
		    interpolator: timer_interpolator });
	});
    }
    useInterval(zoomCallback, renderState.isZooming ? delay : null); //NOTE: passing null pauses/stops the execution.

    const canvas = useRef();
    //CRITICAL NOTE: Words can't describe how painful it was to determine this solution. Whatever this 'ResizeObserver'
    //object is, we can expect it will be replaced by another 'solution' as the permanent revolution of Javascript marches on.
    //https://stackoverflow.com/questions/1664785/resize-html5-canvas-to-fit-window
    const observer = new ResizeObserver(setCanvas)
    useEffect(() => {
	//Just manually rip the canvas off the DOM because React is retarded
	let app_element = document.getElementById("App");
	canvas.current = app_element.childNodes[2];
	//window.addEventListener('resize', setCanvas);
	//canvas.current.onresize = setCanvas;
	//NOTE: of all this garbage, this one actually detects the change in the canvas and implements it.
	//Now do this above and add the correct data to the RenderContext.
	observer.observe(canvas.current);
    }, []);

    let fakeInit = useRef(false);

    //TODO: Will have to tune the timeout value here, or better wait until a signal that the
    //plant is at equilibrium
    const waitOnCanvas = (data) => {
	var timeout_milliseconds = 500;
	var elapsed = performance.now();
	var cond = (checkCanvas(canvasSize) &&
		    fakeInit.current) || elapsed > 1000 * 10;
	return new Promise(resolve => setTimeout(() => resolve(cond), timeout_milliseconds));
    }

    const checkCanvas = (cs) => {
	return cs.x !== 0 && cs.y !== 0;
    }
    if(!checkCanvas(canvasSize) && !fakeInit.current)
    {
	//Wait for canvas to be (0, 0). Set an init'd flag.
	console.log("fake init canvas");
	fakeInit.current = true;
    }

    let ignoreManualRouteUpdate = useRef(false);
    useEffect(() => {
	if(window.location.pathname == "/") {}
	else if(!ignoreManualRouteUpdate.current)
	{
	    console.log("Attempt route transfer");
	    console.log(window.location);		
	    async function wC() {
		var go = false;//await waitOnCanvas();
		while(!go)
		{
		    go = await waitOnCanvas();
		}
		return(go);
	    }

	    let illuInit = wC();
	    illuInit.then((result) => {
		if(result)
		{
		    if(window.location.pathname == "/research")
		    {
			researchPageClick();
		    }
		    else if(window.location.pathname == "/software")
			softwarePageClick();
		    else if(window.location.pathname == "/games")
			gamesPageClick();
		}
	    });
	}
    }, [window.location.pathname]);

    let manualRouteUpdate = (route) => {
	window.history.pushState({}, undefined, route);
	ignoreManualRouteUpdate.current = true;
    }
    useEffect(() => {
        if(renderState.page == "Research")
	{
	    if(window.location.pathname != "/research")
	    {
		manualRouteUpdate("/research");
	    }
	}
	else if(renderState.page == "Software")
	{
	    if(window.location.pathname != "/software")
	    {
		manualRouteUpdate("/software");
	    }
	}
	else if(renderState.page == "Games")
 	{
	    if(window.location.pathname != "/games")
	    {
		manualRouteUpdate("/games");
	    }
	}
	else
	{
	    //Little hack to prevent transitions to blank route on page start (check prev page)
	    if(window.location.pathname != "/" && renderState.prevPage != "")
	    {
		manualRouteUpdate("/");
	    }
	}
    }, [renderState.page]);

    const WrappedIllustration = memo((props) => {	
	return(<React.Fragment>

	       </React.Fragment>);
     });
    let wi = <WrappedIllustration renderState={renderState} zoomTarget={plantOffset.current}
				  canvasSize={canvasSize}
				  pageInterpolatorCallback={pageInterpolatorCallback}
				  updatePageState={updatePageState}/>

    //We need to get this to a point where it doesn't actually refresh page / rerender entire app.
    const router = createBrowserRouter(
	createRoutesFromElements(
	    <Route path="/" element={<React.Fragment/>}>
		<Route path="research" element={<React.Fragment/>}/>
		<Route path="software" element={<React.Fragment/>}/>
		<Route path="games" element={<React.Fragment/>}/>
            </Route>
	)
    );
		   
    //NOTE: canvas background color is set by CSS would prefer to have it in here
    //as program state somehow. It's also hard-coded in PageRenderer, would prefer as props...
    return (
	<div id="App">
	    <AppBar position="fixed">
		{/*<IconButton>
		    <MenuIcon/>
		    </IconButton>*/}
		<Toolbar>
		    <Typography variant = "h6" component="div"
				sx={{flexGrow: 1, display: { xs: 'none', sm: 'block' }}}>
			Adam Chmurzynski
		    </Typography>
		    <Typography variant = "h6" component="div"
				sx={{flexGrow: 1, display: { xs: 'none', sm: 'block' }}}>
			Ecology & Game Development
		    </Typography>
		    <BoxMUI sx={{display: { xs: 'none', sm: 'block' } }}>
		        <Button color="inherit" onClick={researchPageClick}>Research</Button>
			<Button color="inherit" onClick={softwarePageClick}>Software</Button>
			<Button color="inherit" onClick={gamesPageClick}>Games</Button>
		    </BoxMUI>
		</Toolbar>
	    </AppBar>

	{<RouterProvider router={router}/>}
	{/*NOTE: Any state update suffices to re-render this canvas - usually on animated
	  component timers throughout the drawing. Controlling these is not easy*/}
	    <Illustration frameloop={'demand'} element = {'canvas'} zoom={renderState.illuZoom}
			  pointerEvents={true} dragRotate={false} centered={false}>
	        {/*<DragControl/>*/}
		<RenderContext.Provider value={renderState}>
		    <Scene target={plantOffset.current} canvasSize={canvasSize}
		          pageInterpolatorCallback={pageInterpolatorCallback}
		           updatePageState={updatePageState}/>
		    <PageRenderer canvasSize={canvasSize} page={renderState.page}
				  render={renderState.render} isZooming={renderState.isZooming}/>
		</RenderContext.Provider>
	    </Illustration>
	</div>
    );
}

export default App;

export const ZText = React.memo(function ZText(props) {
    const { illu, scene, size} = useZdog()

    let myFont = new Zdog.Font({src:freedom}); 

    let makeZTextFromProps = () => {
	return(new Zdog.Text({
	    addTo: illu,
	    translate: props.position,
	    font: myFont,
	    value: props.text,
	    visible: false,
	    fontSize: 64,
	    //stroke: props.size,
	    stroke: 0,
	    color: '#fff',
	    scale: props.scale
	}));
    }

/*    const [zText, setZtext] = useState(() => {
    	console.log("Instantiate ZText state and add to illustration") 
    	return(new Zdog.Text({
	    addTo: illu,
	    translate: props.position,
	    font: myFont,
	    value: props.text,
	    visible: props.visible,
	    fontSize: 64,
	    stroke: props.size,
	    color: '#fff',
	    scale: props.scale
	}));
    });
*/
    //NOTE: HAve to set the initial state with a function declaration so that it's only called once in the lifecycle of the
    //component. React things...
    //Need to be able to delete the old Ztext object I think
    const [zText, setZtext] = useState(makeZTextFromProps);

    //NOTE: This is somewhat unique to ZText because it's a more normal Zdog style of writing. We don't even have a Render call here,
    //and we just directly update state on re-render, which references the underlying Zdog illustration implicitly.
    zText.value = props.text;
    zText.stroke = props.size;
    zText.visible = props.visible;
    zText.translate = props.position;
    zText.scale = props.scale;

    //console.log("render ZText");
})
