import {Fragment, useState, useEffect, useRef, useContext} from 'react'
import Zdog from 'zdog';
import { Shape, Hemisphere, useZdog, useRender, useInvalidate} from "react-zdog";

import {RenderContext, useInterval, useTraceUpdate} from './App';

function Leaf(props) {    

    //const [leafApex, setLeafApex] = useState(() => { return(props.restApex)});    
    //DO NOT SET THE INIT VALUE OF A REF TO THE ACTUAL PROPS FIELD. CRAZY SHIT ENSUES
    //const leafApex = useRef(props.restApex)
    //You can copy it but it's unlikely to have the initial value we want on first-frame anyway
    //const leafApex = useRef(props.restApex.copy());
    const leafApex = useRef(new Zdog.Vector());
    
    //let midpoint = getLeafMidpoint(props.origin, props.restApex);
    //const arc1 = useRef(new Zdog.Vector({x:midpoint.y, y:-midpoint.x}).add(midpoint));
    //const arc2 = useRef(new Zdog.Vector({x:-midpoint.y, y:midpoint.x}).add(midpoint));
    const arc1 = useRef(new Zdog.Vector());
    const arc2 = useRef(new Zdog.Vector());

    //const rotation_target = new Zdog.Vector({x:0, y: 0, z: 3*Zdog.TAU/4});
    const rest_rotation_target = 3*Zdog.TAU/6;
    const page_render_rotation_target = Zdog.TAU/2;

    //const leafRotationTimer = useRef(0.0);
    const [leafRotationTimer, setLeafRotationTimer] = useState(() => {return 0});
    
    const leafRotationAxis = useRef(new Zdog.Vector({x:rest_rotation_target, y:rest_rotation_target}));
    const hoverGracePeriod = useRef(0.2)

    const midpoint = useRef(new Zdog.Vector());
    const bladeOffset = useRef(new Zdog.Vector());

    const rot_d = useRef(new Zdog.Vector());
    
    function leafUpdate(expected_rotation)
    {
	midpoint.current.set(new Zdog.Vector({x: leafApex.current.x, y: leafApex.current.y}));
	midpoint.current.subtract(props.origin);

	//NOTE: Get the direction along the midrib, scaled by the expected rotation
	leafRotationAxis.current.set(midpoint.current.copy());
	rot_d.current.set(midpoint.current.copy())

	let rotationAxisMagnitude = leafRotationAxis.current.magnitude();
	leafRotationAxis.current.set({x: leafRotationAxis.current.x / rotationAxisMagnitude,
				      y: leafRotationAxis.current.y / rotationAxisMagnitude, z:0})
	leafRotationAxis.current.multiply(expected_rotation);
	
	//NOTE: Get vector orthogonal to midrib
	bladeOffset.current.set(new Zdog.Vector({x:midpoint.current.y, y:-midpoint.current.x}));
	//NOTE: Get the actual midpoint
	midpoint.current.multiply(0.5);
	midpoint.current.add(props.origin);
	
	arc1.current.set(midpoint.current);
	arc2.current.set(midpoint.current);

	let leaf_width = props.leafSize*props.leafWidthFactor;
	let blade_offset_magnitude = bladeOffset.current.magnitude();
	bladeOffset.current.set({x: bladeOffset.current.x * leaf_width / blade_offset_magnitude,
				 y: bladeOffset.current.y * leaf_width / blade_offset_magnitude})
	bladeOffset.current.rotate(leafRotationAxis.current);
	
	
	arc1.current.add(bladeOffset.current);
	arc2.current.subtract(bladeOffset.current);
    }

    //Hooks make copies of everything and run independently at the time they're executed. Seems only useRef will 
    //reliably get data into here. This will be our overall update pattern though - avoid state, use refs for internal
    //updates, and hook directly into the render loop with a time parameter.
    const leafAnimate = (interval) => {	
	setLeafRotationTimer(leafRotationTimer + (interval * 0.005));
	var normalized_rotation; 
	if(props.hover)
	{
	    //leafRotationTimer.current += (interval * 0.005);
	    //NOTE: On pointer enter hover, would be helpful to set relative to the current, rather than just the target
	    hoverGracePeriod.current = 0;
	    let normalized_current = Zdog.modulo(leafRotationTimer, rest_rotation_target);
	    //normalized_current=leafRotationTimer;
	    normalized_rotation = normalized_current;
	}
	else if(hoverGracePeriod.current <= 1)
	{
	    hoverGracePeriod.current += (interval * 0.0025);
	    let normalized_current = Zdog.modulo(leafRotationAxis.current.x, rest_rotation_target);
	    normalized_rotation = Zdog.lerp(normalized_current, rest_rotation_target, hoverGracePeriod.current);
	}
	else
	{
	    //NOTE: When this is enabled and we move the plant, there's a clue here as to why the leaf rotations are so wonky
	    //normalized_rotation = rest_rotation_target;
	}
	
	leafApex.current.set(props.restApex.copy())
	leafUpdate(normalized_rotation);
    }
    
    let delay = 10

    //And we can't exactly do this reactively unless we pass timer state from some other function down to here in order to
    //compute the deltaTime in the animate function. Which is very possible with RenderContext but might straight-jacket us.
    //Problem with using the renderContext is that other leaves are calling this too when clicked/zooming.
    const renderContext = useContext(RenderContext);
    const leafCallback = () => {
	leafAnimate(delay);
    }
    useInterval(leafCallback, ((props.hover || hoverGracePeriod.current <= 1) && (!renderContext.isZooming && renderContext.render))
		? delay : null)

    //Just a regular update for when we re-render - but seems to be interfering with other stuffs
    useEffect(() => {
	if(!renderContext.isZooming && renderContext.render)
	{
	//leafUpdate(leafRotationAxis.current.x);
	leafAnimate(0); //leafAnimate updates the leafApex, but we pass 0 so we don't rotate or anything
	}
    }, [props, renderContext])

    //NOTE: This is driven by the fact that subscribing to RenderContext gives us re-renders
    const zoomCallback = () => {
	let lerp_apex = leafApex.current.copy().lerp(props.targetApex, renderContext.interpolator)
	leafApex.current.set(lerp_apex);

	let normalized_current = Zdog.modulo(leafRotationAxis.current.x, page_render_rotation_target);
	let lerp_rotation = Zdog.lerp(normalized_current, page_render_rotation_target, renderContext.interpolator);

	leafUpdate(lerp_rotation);
    } //TODO: This doesn't render the leaves on zoom-out because renderContext.page == "".
    useInterval(zoomCallback, (renderContext.isZooming && renderContext.page == props.page) ? delay : null)

    return(<Fragment>
	       <Shape path={[props.origin,
			 { arc: [ arc1.current,
				  leafApex.current
				]},
			 { arc: [ arc2.current,
				  props.origin
				]}
			]}
		  fill={true} 
		  closed= {true}
		  stroke={1}
		  color={"#50C878"}
		  onClick={props.onClick}
		  onPointerMove={props.onPointerMove}
		  onPointerEnter={props.onPointerEnter}
		      onPointerLeave={props.onPointerLeave}/>

	       {/*Cool outline*/}
	       {props.hover && <Shape path={[props.origin,
			 { arc: [ arc1.current,
				  leafApex.current
				]},
			 { arc: [ arc2.current,
				  props.origin
				]}
			]}
		  fill={false} 
		  closed= {false}
		  stroke={1}
		  color={"#000000"}/>}
	       {/*<Hemisphere translate={arc1.current} diameter={5} stroke={true} color={'#FFA500'}/>
	       <Hemisphere translate={arc2.current} diameter={5} stroke={true} color={'#84741F'}/>
	       <Shape path={[ midpoint.current, rot_d.current ]} color="#636"/>
	       <Shape path={[ props.origin, leafApex.current ]} color="#636"/>*/}
	   </Fragment>
	  );
}

export default Leaf
