import Plant from './Plant'
import Zdog from 'zdog';

import { Shape, Anchor, Hemisphere, Ellipse, Cone, Box, useZdog, useRender, useInvalidate} from "react-zdog";
import React, {useState, useEffect, useRef, useCallback, useContext, createContext} from 'react'

import {RenderContext, useInterval, useTraceUpdate} from './App';

import * as Easing from './easing'

export const Scene = (props) => {
    //These data are usable because we have props coming in that update the canvas at the right times. These are not reactive
    //quantities though, which breaks some promises that Zdog and especially react-zdog makes
    const { illu, scene, size} = useZdog()

    //useTraceUpdate(props);
    
    const [sunClicked, setSunClicked] = useState(() => {return(false)});
    const onClick = (e, ele) => {
	setSunClicked(!sunClicked);
    };
    
    const renderContext = useContext(RenderContext);

    //Options for re-scaling:
    //-define our development canvas dimensions, and re-scale all the finalized points by comparing with size
    // Guess we're going with this one.
    let dev_x = 1920, dev_y = 971;

    //These need to go down to drawing components, but only at render-time
    let scale_x = props.canvasSize.x / dev_x, scale_y = props.canvasSize.y / dev_y;

    const [sunDiameter, setSunDiameter] = useState(100);
	    
    let sunHorizonMaximum = props.canvasSize.y*0.2
    const sunOrigin = useRef(new Zdog.Vector({x: props.canvasSize.x*0.6, y: sunHorizonMaximum}));
    const origin = useRef(new Zdog.Vector({x: props.canvasSize.x/2, y: props.canvasSize.y*0.9}));
    
    //NOTE: IF you don't use the setters in useState, you won't get re-renders and things will get messed up.
    const [sunPosition, setSunPosition] = useState(() => {return(sunOrigin.current.copy())});
    
    //NOTE: Going to try to manage all app resizing here, and propagate changes down to the rest of the drawing.
    //TODO: Would prefer to detect this at top-level component and provide data in RenderContext. Would require another
    //layer of nesting (wrapping RenderContext and providing it, rather than App doing, so we can call useZdog)
    //or passing RenderContext here
    useEffect(() => {
	if(props.canvasSize)
	{
	    if(renderContext.render)
	    {
		origin.current.set({x: props.canvasSize.x/2, y: props.canvasSize.y*0.95});
		if(sunPosition.x == 0 && sunPosition.y == 0)
		{
		    sunOrigin.current.set({x: props.canvasSize.x*0.4, y: sunHorizonMaximum});
		    setSunPosition(sunOrigin.current.copy()); //This successfully triggers a re-render
		}
		else
		{
		    //TODO: NNot quite right - has to transform from teh previous canvas coordinates, not just scaling from the dev ones.
		    setSunPosition(sunPosition.copy().multiply({x:scale_x, y:scale_y}));
		}
	    }
	}
    }, [props.canvasSize]);

    //TODO: Having mouse as state causes re-renders - maybe only want this during click, somehow. Or, just have it as a ref,
    //and let the sun re-render itself as position changes.
    //const [mousePosition, setMousePosition] = useState(() => {return(sunOrigin.current)})
    const mousePosition = useRef(sunOrigin.current.copy());
    const setMouseMovement = useCallback(e => {
	if(sunClicked && renderContext.render)
	{
	    //setMousePosition({x: e.clientX, y: e.clientY});
	    mousePosition.current.set({x: e.clientX, y: e.clientY});
	}
    });
    useEffect(() => {
	illu.ctx.canvas.addEventListener('mousemove', setMouseMovement)
	//Returning the removal ensures we're adding/removing each time the hook is called, which is retarded
	return () => {illu.ctx.canvas.removeEventListener('mousemove', setMouseMovement); }
    }, [setMouseMovement]);

    const sunCallback = () => {
	let sun_vector = sunPosition.copy();
	let canvas_mouse_pos = {x: mousePosition.current.x, y: mousePosition.current.y}
	sun_vector.subtract(canvas_mouse_pos);
	let sun_speed = 0.1
	if(sun_vector.magnitude() > 10)
	{
	    //We need the sun to do this behavior itself when sun position is far from cursor position.
	    sun_vector.multiply(sun_speed); 
	    let newSunPosition = sunPosition.copy();
	    newSunPosition.subtract(sun_vector);
	    //TODO: Apply this to the mouse position or somesuch before doing physics.
	    if(newSunPosition.y > sunHorizonMaximum)
	    {
		newSunPosition.set({x: newSunPosition.x, y: sunHorizonMaximum})
	    }
	    if(newSunPosition.x < (sunDiameter / 2))
	    {
		newSunPosition.set({x: (sunDiameter / 2), y: newSunPosition.y})
	    }
	    else if(newSunPosition.x > (props.canvasSize.x - (sunDiameter / 2)))
	    {
		newSunPosition.set({x: (props.canvasSize.x - (sunDiameter / 2)), y: newSunPosition.y})
	    }
	    //Does this need to be a ref?
	    setSunPosition(newSunPosition); //This makes the plant reactive under sun movement.
	    //sunPosition.subtract(sun_vector); //Memoized plant doesn't react to this, no change in underlying reference
	}
    }
    let delay = 10
    //useInterval(sunCallback, renderContext.render ? delay : null);

    let scaled_origin_offset = origin.current.copy().subtract(origin.current.copy().multiply(scale_y));
    let offset_target = props.target.copy().add(scaled_origin_offset)
    return(<Anchor translate={offset_target} scale={scale_y}>
	       {<Sun position={sunPosition} diameter={sunDiameter} scale={scale_x}
		  sunClick={sunClicked} onSunClick={onClick}/>}
	       <Plant origin={origin.current}
		      scaleX={scale_x} scaleY={scale_y}
		      sunPosition={sunPosition} sunClicked={sunClicked}
		      page={renderContext.page} prevPage={renderContext.prevPage}
		      render={renderContext.render} isZooming={renderContext.isZooming}
		      pageUpdate={renderContext.pageUpdate}
	              renderPageCallback={props.pageInterpolatorCallback}
		      updatePageState={props.updatePageState}/>
	       <Soil origin={origin.current} width={props.canvasSize.x*2}/>
	   </Anchor>);
}

const Sun = (props) => {
    const renderContext = useContext(RenderContext);

    const sunRef = useRef();
    let delay = 10;
    const sunCallback = () => {
	//Probably want to have some rays from the sun, and rotate on mouse over or something cute
	//sunRef.current.rotate.y += 0.05
	if(sunRef.current)
	{
	    sunRef.current.scale.set({x: props.scale, y:props.scale, z:props.scale});
	}   
    }
    //useInterval(sunCallback, renderContext.render ? delay : null); //NOTE: passing null pauses/stops the execution.
    let sunRotate = props.sunClick ? Zdog.TAU/2 : Zdog.TAU;
    let sunColor = props.sunClick ? '#FF6E00' : '#FFA500';
    //May be Zdog's scaling bug with these shapes. Find a way to delete / re-init, changing diameter may be scalig under the hood.
    return(<Anchor translate={props.position} >
	       <Ellipse ref={sunRef} diameter={props.diameter} rotate={{y:sunRotate}}
			stroke={true} color={sunColor} fill = {true} onClick={props.onSunClick}/>
	       {props.sunClick && <Corona diameter={props.diameter}/>}
	   </Anchor>);
}

const Corona = React.memo((props) => {
    const corona = [];
    const coronaNumber = 8;
    const whorl = Zdog.TAU / coronaNumber;
    const getCorona = [...Array(coronaNumber).keys()].forEach((i) => {
	let petal_axis = new Zdog.Vector({z: whorl * i});
	let petal_margin = new Zdog.Vector({x: 0, y: props.diameter});
	petal_margin.rotate(petal_axis);

	//Adding keys makes this phenomenally slower, for totally unknown reasons.
	corona.push(<Ellipse diameter={props.diameter/10}
			     translate={petal_margin} rotate={petal_axis}
			     color='#FFA500' fill={true} stroke={false}/>);
    })

    return(<>
	       {corona}
	   </>);
})

function Soil(props) {
    let height = 1000
    let soil_offset = useRef();
    soil_offset.current = props.origin.copy().set({x: props.width / 2, y:props.origin.y + height/2})
    return(<Box width={props.width* 100} height={height} translate={soil_offset.current}
		depth={80} stroke={false} color={'#7c4807'}
		leftFace={'#7c4807'} rightFace={'#7c4807'}
		rearFace={'#7c4807'} bottomFace = {'#888c8d'}
		frontFace={false} topFace ={false}/>);
}
