import React, {Fragment, useRef, useEffect, useCallback, memo} from 'react';
import { useZdog } from "react-zdog";

import { useInterval, useTraceUpdate } from './App';

import guanacaste from './assets/img/parque-nacional-guanacaste-cover.jpg'
import halle from './assets/img/cahier-coloriages-fondation-cartier-francis-halle.jpg'
import CV from './assets/CV.pdf'

import hdr from './assets/img/hdr.png'
import biorbis from './assets/img/biorbis.png'
import lol from './assets/img/libraries_of_life.png'
import scan from './assets/img/san_emilio_scan.png'
import tls from './assets/img/TLS.png'
import fig from './assets/img/Figure_B.png'

import plapp from './assets/img/plapp.png'
import capoeira from './assets/img/capoeira.jpg' //Rendering a gif will require more muscle and a lot of images to loop through
import chamberlain from './assets/img/chamberlain.png'
//TODO: Need screenshot from Bodies (and those in itch.io)

let tree_arch_img = new Image();
tree_arch_img.src = halle;
tree_arch_img.loading = "eager";

let img_element = new Image();
img_element.src = guanacaste;
img_element.loading = "eager";

let hdr_img = new Image();
hdr_img.src = hdr;
hdr_img.loading = "eager";

let biorbis_img = new Image();
biorbis_img.src = biorbis;
biorbis_img.loading = "eager";

let lol_img = new Image();
lol_img.src = lol;
lol_img.loading = "eager";

let scan_img = new Image();
scan_img.src = scan;
scan_img.loading = "eager";

let tls_img = new Image();
tls_img.src = tls;
tls_img.loading = "eager";

let fig_img = new Image();
fig_img.src = fig;
fig_img.loading = "eager";

let plapp_img = new Image();
plapp_img.src = plapp;
plapp_img.loading = "eager";

let capoeira_img = new Image();
capoeira_img.src = capoeira;
capoeira_img.loading = "eager";

let chamberlain_img = new Image();
chamberlain_img.src = chamberlain;
chamberlain_img.loading = "eager";

//NOTE: memo prevents re-renders when we're passing interpolator through RenderState.
//But we still end up forcing re-renders because props check RenderState by reference,
//which is always updated that way. React weird
const PageRenderer = memo(function PageRenderer(props) {    	
    const { illu, scene, size} = useZdog()

    const initial_canvas = useRef(document.createElement('canvas'))

    const cv_link = "CV.pdf";
    const inCVLink = useRef(false);
    
    const forestgeo_link = "https://forestgeo.si.edu/sites/san-emilio"
    const inForestGEOLink = useRef(false);

    const github_link = "https://github.com/achmurzy"
    const inGithubLink = useRef(false);

    const itch_link = "https://achmurzy.itch.io/"
    const inItchLink = useRef(false);
    
    //if the link has been clicked, go to link
    //Somehow, this needs to invalidate click events on the Zdog canvas options. But only for this
    //exact region/case.
    function onLinkClick(e, link, inLink) {
	if (inLink)  {
	    window.open(link, '_blank').focus();
	}
    }

    //http://fahdshariff.blogspot.com/2011/06/html5-adding-links-to-canvas.html
    //check if the mouse is over the link and change cursor style
    function onMouseOverLink (ev, linkX, linkY, linkWidth, linkHeight, inLink) {
	var x, y;
	
	// Get the mouse position relative to the canvas element.
	if (ev.layerX || ev.layerX) { //for firefox
	    x = ev.layerX;
	    y = ev.layerY;
	}

	//is the mouse over the link?
	if(x>=linkX && x <= (linkX + linkWidth) &&
	   y<=linkY && y>= (linkY-linkHeight)){
	    document.body.style.cursor = "pointer";
	    inLink.current = true;
	}
	else{
	    document.body.style.cursor = "";
	    inLink.current = false;
	}
    }

    const itchClickListener = useRef();
    //NOTE: Snapshot the canvas when we stop zooming and stop rendering the Zdog flow
    //May be best to draw the page once, then take the snapshot. Then we could just redraw the snapshot in update, scaled,
    //rather than recalculating everything. Depends on if you want reactive stuff though.
    const snapshotCanvas = () => {
	if(!props.render && !props.isZooming)
	{
	    if(props.page == "Research")
		renderResearchPage(illu.ctx, props.canvasSize);
	    else if(props.page == "Software")
		renderSoftwarePage(illu.ctx, props.canvasSize);
	    else if (props.page == "Games")
	    {
		let linkMetadata = renderGamesPage(illu.ctx, props.canvasSize);

		//These need to be moved out into the component context to keep a named reference
		//that the canvas listener API can keep track of. Making link metadata into
		//state or refs that can react to the canvas snapshot is the clearest way
		function itchMouseListener(ev) {
		    onMouseOverLink (ev, linkMetadata.linkX, linkMetadata.linkY, linkMetadata.linkWidth,
				     linkMetadata.linkHeight, inItchLink);
		}
		illu.ctx.canvas.addEventListener("mousemove", itchMouseListener, false);

		//Storing the callback in a ref works for managing this over re-renders
		illu.ctx.canvas.removeEventListener("click", itchClickListener.current, false);
		itchClickListener.current = (e) => {
		    onLinkClick(e, itch_link, inItchLink.current)
		};
		illu.ctx.canvas.addEventListener("click", itchClickListener.current, false);
	    }
	    else
	    {
		console.log("nada");
	    }
	    
	    initial_canvas.current.width = props.canvasSize.x;
	    initial_canvas.current.height = props.canvasSize.y;

	    //TODO: Background color is alpha then set by CSS. We have to color the transparent pixels in the initial canvas
	    //snapshot for this to work properly. CSS strikes again
	    //initial_canvas.current.getContext('2d').drawImage(illu.ctx.canvas, 0, 0, props.canvasSize.x, props.canvasSize.y,
	    //						      0, 0, props.canvasSize.x, props.canvasSize.y);
	    let canvas_snapshot_pixels = illu.ctx.getImageData(0,0,props.canvasSize.x, props.canvasSize.y);
	    var pix = canvas_snapshot_pixels.data;
	    for(var i = 0, n = pix.length; i < n; i += 4)
	    {
		if(pix[i+3] == 0)
		{
		    //NOTE: Hard-coded the RGB for background - it'll be obvious if this changes on us
		    pix[i] = 100;
		    pix[i+1] = 149;
		    pix[i+2] = 237;
		    pix[i+3] = 255;
		}
	    }
	    
	    let ctx=initial_canvas.current.getContext("2d");
	    ctx.putImageData(canvas_snapshot_pixels, 0, 0);
	    /*initial_canvas.current.getContext('2d').fillStyle = "orange"
	    initial_canvas.current.getContext('2d').fillRect(0, 0, 100, 100)
	    initial_canvas.current.getContext('2d').fillRect(0,
							     initial_canvas.current.height - 100, 100, 100)
	    initial_canvas.current.getContext('2d').fillRect(100,
							     initial_canvas.current.height - 200, 100, 100)
	    initial_canvas.current.getContext('2d').fillRect(0,
							     initial_canvas.current.height - initial_canvas.current.height/2, 100, 100)
	    initial_canvas.current.getContext('2d').fillRect(initial_canvas.current.width - 100,
	    initial_canvas.current.height - 100, 100, 100)*/
	    console.log("Draw page snapshot");
	}
    }
    useEffect(snapshotCanvas, [props.isZooming])

    let delay = 100;
    const pageCallback = () => {
	if(!(props.page == "" || props.isZooming || props.render))
	{
	    	console.log("try redraw canvas");
	    illu.ctx.clearRect(0, 0, props.canvasSize.x, props.canvasSize.y);
	    illu.ctx.drawImage(initial_canvas.current, 0, 0,
			       initial_canvas.current.width, initial_canvas.current.height,
			       0, 0, props.canvasSize.x, props.canvasSize.y);
	}
    }
    //useEffect(pageCallback, [props]);
    //NOTE: We shouldn't have to do this. However, something with the initial canvas pixel data
    //initialization is delayed and it needs to be called a little bit later.
    useInterval(pageCallback, (props.page == "" || props.isZooming || props.render) ? null : delay); 

    const renderResearchPage = (context, canvasSize) => {
	let dev_x = 1920, dev_y = 971;
	let scale_x = canvasSize.x / dev_x, scale_y = canvasSize.y / dev_y;
	
	let left_x = 420;
	let right_x = 1200;

	let text_y_top = 150;
	let text_y_bottom = 550;
	let top_y = 50
	let bottom_y = 400

	context.save();
	context.scale(scale_x, scale_y);

	let tree_arch_scale = 0.25
	context.drawImage(tree_arch_img, 0, 0, 1200, 1700, right_x, top_y, tree_arch_scale*1200, tree_arch_scale*1700)

	let guanacaste_scale = 0.5;	
	let subrect_width = 1000
	let subrect_height = 550
	context.drawImage(img_element, 150, 0, subrect_width, subrect_height,
			  left_x, bottom_y, guanacaste_scale*subrect_width, guanacaste_scale*subrect_height);

	//NOTE: Likely a better way, but just going to muscle it for now
	let arbitrary_text_scale = 2
	context.save();
	context.scale(arbitrary_text_scale, arbitrary_text_scale);

	let scale_top_text_x = left_x / arbitrary_text_scale;
	let scale_top_text_y = text_y_top / arbitrary_text_scale;
	context.fillText("I study the evolutionary origins and ecological consequences of",
			 scale_top_text_x, scale_top_text_y);
	context.fillText("variation in tree size and shape.", scale_top_text_x, scale_top_text_y + 10)
	context.fillText("I use a combination of botanical field data and remote sensing techniques",
			 scale_top_text_x, scale_top_text_y + 30);
	context.fillText("for the creation and analysis of detailed geometric models of trees in nature.",
			 scale_top_text_x, scale_top_text_y + 40);
	context.fillText("These models reveal new dimensions of plant biodiversity and",
			 scale_top_text_x, scale_top_text_y + 60);
	context.fillText("help forecast the future of forested ecosystems in a changing world.",
			 scale_top_text_x, scale_top_text_y + 70);

	/*let linkWidth = illu.ctx.measureText(cv_link).width;
	context.fillStyle = inCVLink.current ? "orange" : "green";
	context.fillRect(scale_top_text_x, (scale_top_text_y + 80), linkWidth, 10);
	context.fillStyle = "black";
	context.fillText(cv_link, scale_top_text_x, scale_top_text_y + 90);

	const cvMouseListener = (ev) => {
	    onMouseOverLink (ev, scale_top_text_x*2, (scale_top_text_y + 80)*2,
			     linkWidth*arbitrary_text_scale, 10*arbitrary_text_scale, inCVLink) };
	context.canvas.addEventListener("mousemove", cvMouseListener, false);
	const cvClickListener = (e) => { onLinkClick(e, CV, inCVLink.current) };
	context.canvas.addEventListener("click", cvClickListener, false);
        */

	let scale_bottom_text_x = (right_x - 250) / arbitrary_text_scale;
	let scale_bottom_text_y = text_y_bottom / arbitrary_text_scale;
	context.fillText("In Guanacaste, Costa Rica I maintain long-term forest plots",
			 scale_bottom_text_x, scale_bottom_text_y);
	context.fillText("as a natural laboratory, applying our understanding of",
			 scale_bottom_text_x, scale_bottom_text_y + 10);
	context.fillText("plant physiology to the functioning of forests on landscapes.",
			 scale_bottom_text_x, scale_bottom_text_y + 20);

	context.fillText("With funding from a National Geographic Explorer grant and in collaboration",
			 scale_bottom_text_x, scale_bottom_text_y + 40);
	context.fillText("with the Smithsonian Institution, we have recently added the 16 hectare",
			 scale_bottom_text_x, scale_bottom_text_y + 50);
	context.fillText("San Emilio Forest Dynamics Plot to the international ForestGEO plot network.",
			 scale_bottom_text_x, scale_bottom_text_y + 60);

	let linkWidth = illu.ctx.measureText(forestgeo_link).width;
	//Not working without reactive redraws on this page - will likely do.
	context.fillStyle = inForestGEOLink.current ? "orange" : "green";
	context.fillRect(scale_bottom_text_x, (scale_bottom_text_y + 70), linkWidth, 10);
	context.fillStyle = "black";
	context.fillText(forestgeo_link, scale_bottom_text_x, scale_bottom_text_y + 80);

	//Need to remove these listeners somehow when we exit the page.
	//Probably need to remake them when we resize the page too.
	context.canvas.addEventListener("mousemove", (ev) => {
	    onMouseOverLink (ev, scale_bottom_text_x*2, (scale_bottom_text_y + 90)*2,
			     linkWidth*arbitrary_text_scale, 10*arbitrary_text_scale, inForestGEOLink) }, false);
	context.canvas.addEventListener("click", (e) => { onLinkClick(e, forestgeo_link, inForestGEOLink.current) }, false);

	context.restore();
	context.restore();

	//Scaling text requires specifying the font, measuring the output glyphs, then scaling relative to canvas size
	//ctx.font = "20px 'Segoe UI'";
	//let metrix = ctx.measureText("Testing!");
	//let text_width =  metrix.width;
	//let scaleX = (canvas.width / textWidth);
	//let scaleY = (canvas.height / 23); This constant might need to be a function of font and leaf size
	//let ypos = (canvas.height / (scaleY * 1.25));
	//ctx.scale(scaleX, scaleY);
	//ctx.fillText("Testing!", 0, ypos)
	//Not sure how scaling the canvas works - also this scales to the entire canvas rather than a 'text box' we could specify
    }

    const renderSoftwarePage = (context, canvasSize) => {
	let dev_x = 1920, dev_y = 971;
	let scale_x = canvasSize.x / dev_x, scale_y = canvasSize.y / dev_y;

	context.save();
	context.scale(scale_x, scale_y);

	let left_x = 850;
	let text_x = 350;
	let text_y_top = 250;

	let text_y_bottom = 500;
	let top_y = 150
	let bottom_y = 600

	let fig_scale = 0.1
	let fig_x = 4000, fig_y = 3000;
	context.drawImage(fig_img, 100, 120, fig_x, fig_y,
			  left_x, bottom_y+25, fig_scale*fig_x, fig_scale*fig_y);
	context.drawImage(tls_img, 0, 0, 550, 800,
			  left_x + 300, bottom_y + 25, 180, 300);
	
	let hdr_x = 1100
	let biorbis_scale = 0.5
	context.drawImage(biorbis_img, 300, 150, 400, 450, hdr_x-100, top_y + 75, biorbis_scale*600, biorbis_scale*850)

	let hdr_scale = 0.5;	
	let subrect_width = 1200
	let subrect_height = 600
	context.drawImage(hdr_img, 200, 50, subrect_width, subrect_height,
			   hdr_x + 100, top_y+50, hdr_scale*subrect_width, hdr_scale*subrect_height);

	context.drawImage(lol_img, 25, 25, 325, 225,
			   hdr_x + 475, top_y + 180, 325, 225);

	let scan_scale = 0.5
	let scan_x = 1000, scan_y = 1000;
	context.drawImage(scan_img, 200, 300, scan_x, scan_y,
			  hdr_x + 175, top_y + 350, scan_scale*scan_x, scan_scale*scan_y);

	context.save();

	let arbitrary_text_scale = 2
	
	context.scale(arbitrary_text_scale, arbitrary_text_scale);

	let scale_top_text_x = text_x / arbitrary_text_scale;
	let scale_top_text_y = text_y_top / arbitrary_text_scale;
	let scale_bottom_text_y = text_y_bottom / arbitrary_text_scale;
	
	context.fillText("I develop interactive biodiversity visualizations with a variety of", scale_top_text_x, scale_top_text_y);
	context.fillText("3D technologies, including big data web portals, game engines,", scale_top_text_x-25, scale_top_text_y+25);
	context.fillText("XR APIs, and field data from Earth's ecosystems.", scale_top_text_x-25, scale_top_text_y + 50);

	context.fillText("For my scientific work I employ any standard scripting language", scale_top_text_x - 25, scale_bottom_text_y);
	context.fillText("needed to deploy analysis workflows, such as Python, Matlab or Julia.", scale_top_text_x-25, scale_bottom_text_y+25);
	context.fillText("Still, I've primarily written and published R code in the past.", scale_top_text_x-25, scale_bottom_text_y + 50);

	let linkWidth = illu.ctx.measureText(github_link).width;

	//Not working without reactive redraws on this page - will likely do.
	context.fillStyle = inGithubLink.current ? "orange" : "green";
	context.fillRect(scale_top_text_x, (scale_bottom_text_y + 70), linkWidth, 10);
	context.fillStyle = "black";
	context.fillText(github_link, scale_top_text_x, scale_bottom_text_y + 80);

	context.canvas.addEventListener("mousemove", (ev) => {
	    onMouseOverLink (ev, scale_top_text_x*arbitrary_text_scale, (scale_bottom_text_y + 80)*arbitrary_text_scale,
			     linkWidth*arbitrary_text_scale, 10*arbitrary_text_scale, inGithubLink) }, false);
	context.canvas.addEventListener("click", (e) => { onLinkClick(e, github_link, inGithubLink.current) }, false);
	
	context.restore();
	context.restore();
    }

    const renderGamesPage = (context, canvasSize) => {
	let dev_x = 1920, dev_y = 971;
	let scale_x = canvasSize.x / dev_x, scale_y = canvasSize.y / dev_y;

	context.save();
	context.scale(scale_x, scale_y);

	let text_x = 510;
	let text_y_top = 150;
	let text_y_bottom = 600;

	let left_x = 1100;
	let top_y = 175
	let bottom_y = 600

	let plapp_scale = 0.25
	let plapp_x = 700, plapp_y = 1000;
	context.drawImage(plapp_img, 200, 0, plapp_x, plapp_y,
			  left_x, top_y, plapp_scale*plapp_x, plapp_scale*plapp_y);

	let capoeira_x = 500, capoeira_y = 300;
	let capoeira_scale = 0.7
        context.drawImage(capoeira_img, 200, 100, capoeira_x, capoeira_y,
			  left_x-700, top_y + 100, capoeira_scale*capoeira_x, capoeira_scale*capoeira_y);

	let chamberlain_x = 500, chamberlain_y = 670;
	let chamberlain_scale = 0.5
        context.drawImage(chamberlain_img, 0, 0, chamberlain_x, chamberlain_y,
			  left_x-300, top_y + 50, chamberlain_scale*chamberlain_x, chamberlain_scale*chamberlain_y);
	
	context.save();

	let arbitrary_text_scale = 2
	
	context.scale(arbitrary_text_scale, arbitrary_text_scale);

	let scale_top_text_x = text_x / arbitrary_text_scale;
	let scale_top_text_y = text_y_top / arbitrary_text_scale;
	let scale_bottom_text_y = text_y_bottom / arbitrary_text_scale;

	context.fillStyle = "white";
	
	context.fillText("Over the past ten years I've done a significant amount of hobbyist game", scale_top_text_x, scale_top_text_y);
	context.fillText("development, focusing on 3D action-adventure prototypes.", scale_top_text_x-15, scale_top_text_y+25);

	context.fillText("I'm currently working on my first full-length game, code-named 'Canopy'.", scale_top_text_x-50, scale_bottom_text_y);
        context.fillText("It's a 3D puzzle-platforming game about plant ecology, technology,", scale_top_text_x-25, scale_bottom_text_y + 25);
        context.fillText("and restoration and management of Earth's biosphere.", scale_top_text_x, scale_bottom_text_y + 50);

	//TODO: itch.io goes in the flower link, and we can put bodies and stuff up there
	//and a unique UR for Canopy, eventually
	let linkWidth = illu.ctx.measureText(itch_link).width;

	//Not working without reactive redraws on this page - will likely do.
	context.fillStyle = inItchLink.current ? "orange" : "green";
	context.fillRect(scale_top_text_x + 10, (scale_top_text_y + 40), linkWidth, 10);
	context.fillStyle = "black";
	context.fillText(itch_link, scale_top_text_x + 10, scale_top_text_y + 50);

	let linkMetadata = {linkX: scale_top_text_x*arbitrary_text_scale,
			    linkY: (scale_top_text_y + 50)*arbitrary_text_scale,
			    linkWidth: linkWidth*arbitrary_text_scale,
			    linkHeight: 10*arbitrary_text_scale, inLink: inItchLink}
	
	//TODO: Add some canopy URL


	context.restore();
	context.restore();

	return(linkMetadata);
    };

    return(<Fragment/>);
})

export default PageRenderer
