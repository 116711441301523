import {useState, useEffect, useRef, useContext, useCallback, memo} from 'react'
import Zdog from 'zdog';
import { Anchor, Group, Hemisphere, Ellipse, Shape, useZdog, useRender, useInvalidate} from "react-zdog";

import Leaf from './Leaf';
import {ZText, RenderContext, useInterval, useTraceUpdate} from './App';

//NOTE: hacking plant a bit here to prevent re-renders on canvas size changes, which trigger re-render on Scene,
//Remember: memo does absolutely nothing if we subscribe to context here. On the other hand, we lose some reactivity
//unless accoutning for it. If we pass a Zdog.Vector as props, react won't re-render, because it just does a shallow
//comparison on the object. So if numerical values change, it won't notice.
const Plant = memo(function Plant(props) {
    const { illu, scene, size} = useZdog()

    //NOTE: General approach is to rely heavily on Refs to cache data and prevent crazy re-renders,
    //use memo to control exactly when various updates happen at the component level 
    let stem_width = 25;
    let stem_height = 150;
    let middle_length = stem_height*2    
    let stem_bend = 50;
    let stem_curvature = 0.5;
    let bezier_curvature = 0.33;
    
    let sign = props.sunPosition.x > (size.width/2) ? 1 : -1;
    let horizontal_interpolator = 1 - (props.sunPosition.x / (size.width/2));
    if(sign > 0)
	horizontal_interpolator = 2 * (1 - (((size.width/2) / props.sunPosition.x)));

    let stemEnd = useRef(props.origin.copy().add({x: 0, y: -stem_height}));
    stemEnd.current.set(props.origin.copy());
    stemEnd.current.add({x: 0, y: -stem_height})

    let base_tropism = props.sunPosition.copy().subtract(stemEnd.current);
    let tropism_magnitude = base_tropism.magnitude();
    
    base_tropism.multiply(stem_height/tropism_magnitude);
    stemEnd.current.lerp(props.origin.copy().add(base_tropism), horizontal_interpolator);

    let first_cp_height = stem_height*stem_curvature;
    let stemStartCP = useRef(props.origin.copy().add({x: 0, y: -first_cp_height}));
    stemStartCP.current.set(props.origin.copy().add({x: 0, y: -first_cp_height}));
    let first_tropism_factor = {x: 0, y: -first_cp_height - (first_cp_height*horizontal_interpolator)}
    let start_cp_tropism = props.origin.copy().add(first_tropism_factor);
    stemStartCP.current.lerp(start_cp_tropism, horizontal_interpolator);

    let stemEndMiddle = useRef(stemEnd.current.copy().add({x: 0, y: -middle_length}));
    stemEndMiddle.current.set(props.origin.copy().add({x: 0, y: -middle_length}));
    let middle_tropism = props.sunPosition.copy().subtract(stemEndMiddle.current);
    tropism_magnitude = middle_tropism.magnitude();
    middle_tropism.multiply(middle_length/tropism_magnitude);
    stemEndMiddle.current.lerp(stemEnd.current.copy().add(middle_tropism), horizontal_interpolator);

    let second_cp_height = stem_height*bezier_curvature;
    let stemMiddleCP = useRef(stemEnd.current.copy().add({x: 0, y: -second_cp_height}));
    stemMiddleCP.current.set(stemEnd.current.copy().add({x: 0, y: -second_cp_height}));
    let second_tropism_factor = { x: middle_length*sign*horizontal_interpolator,
				  y: -second_cp_height - (second_cp_height * (horizontal_interpolator)) }
    let middle_cp_tropism = stemEnd.current.copy().add(second_tropism_factor);
    stemMiddleCP.current.lerp(middle_cp_tropism, horizontal_interpolator);

    let bezier_cp_height = (stem_height*bezier_curvature*2);
    let stemBezierCP = useRef(stemEnd.current.copy().add({x: 0, y: -bezier_cp_height}));
    stemBezierCP.current.set(stemEnd.current.copy().add({x: 0, y: -bezier_cp_height}));
    let bezier_tropism_factor = { x: middle_length*0.5*horizontal_interpolator*sign,
				  y: -bezier_cp_height - (second_cp_height * horizontal_interpolator) }
    middle_cp_tropism = stemEnd.current.copy().add(bezier_tropism_factor);
    stemBezierCP.current.lerp(middle_cp_tropism, horizontal_interpolator);

    //TODO: Make this dynamic based on sunPosition so we have more headroom in the scene. Do it for all the stem params.
    stem_height = 250;
    //stem_curvature = 0.25;
    
    let stemEndEnd = useRef(stemEndMiddle.current.copy().add({x: 0, y: -stem_height}));
    stemEndEnd.current.set(stemEndMiddle.current.copy().add({x: 0, y: -stem_height}));
    let end_tropism = props.sunPosition.copy().subtract(stemEndEnd.current);
    tropism_magnitude = end_tropism.magnitude();
    end_tropism.multiply(stem_height/tropism_magnitude);
    stemEndEnd.current.lerp(stemEndMiddle.current.copy().add(end_tropism), horizontal_interpolator);

    let third_cp_height = (stem_height*stem_curvature);
    let end_midpoint = stemEndEnd.current.copy();
    end_midpoint.subtract(stemEndMiddle.current);
    end_midpoint.multiply(0.5);
    end_midpoint.add(stemEndMiddle.current);
    let stemEndCP = useRef(end_midpoint);
    stemEndCP.current.set(end_midpoint);
    let third_tropism_factor = { x: third_cp_height*sign, y: 0 }
    let end_cp_tropism = stemEndMiddle.current.copy().add(third_tropism_factor);
    stemEndCP.current.lerp(end_cp_tropism, horizontal_interpolator);

    var leaf_size = 250;
    let little_leaf_size = leaf_size * 0.75

    const targetApex = useRef(new Zdog.Vector())
    const secondTargetApex = useRef(new Zdog.Vector());

    let leafApex = useRef(stemEnd.current.copy());
    leafApex.current.set(stemEnd.current);
    let leaf_direction = stemEnd.current.copy().subtract(props.origin);
    let leaf_mag = leaf_direction.magnitude();
    leaf_direction.set({x: -leaf_direction.y, y: leaf_direction.x}).multiply(leaf_size/leaf_mag);
    leafApex.current.add(leaf_direction); 
    
    let researchTextPos = useRef(leafApex.current.copy().add({x: 25, y:0}));
    researchTextPos.current.set(leafApex.current.copy().add({x: 25, y:0}));
	      
    const [bigLeafHover, setBigLeafHover] = useState(() => {return(false)});
    const bigLeafCallback = useCallback(() => {
	if(props.render)
	{
	    targetApex.current.set({x: stemEnd.current.x + leaf_size, y: stemEnd.current.y});
	}
	else
	{
	    targetApex.current.set(leafApex.current.copy());
	    setBigLeafHover(false);
	}
	//NOTE: Actual leaf margin is hard to get from the quadratic margin. ~20% is close enough for now,
	//given the zoom we're using and dev screen coords.
	let leaf_width = leaf_size/5.5 	
	let leaf_center_offset = new Zdog.Vector({x: 0, y: leaf_width})
	let offset = stemEnd.current.copy().multiply(-1).add(leaf_center_offset)
	//Offset is wrong/needs to be scaled.
	props.renderPageCallback(9.5*props.scaleY, offset);
    });
    
    const onBigLeafClick = (e, ele) => {
	if(props.render)
	{
	    props.updatePageState("Research", false);
	    bigLeafCallback();
	}
	else
	{
	    props.updatePageState("", false);
	    props.renderPageCallback(1, new Zdog.Vector());
	}
    };

    const onPointerMove = (e, ele) => {
	//disable leaf rotate here, rather than on pointer exit
    };

    const onBigLeafPointerEnter = (e, ele) => {
	if(props.render && !props.isZooming)
	{
	    //TODO: Triggers plant re-render - need to find a different way. Should be ref not state. OR something
	    setBigLeafHover(true);
	}
    };

    const onBigLeafPointerExit = (e, ele) => {
	if(props.render && !props.isZooming)
	{
	    //Need to be smarter about turning this off, possibly doing a custom distance check of some kind,
	    //because rotating the leaf with pointer events is a little inconsistent, even if we add a grace period
	    //there are 'dead zones' that appear inside the leaf's rotation hull. Simple offset from that hull
	    //i.e. 2Daxis-aligned bounding box on the leaf
	    setBigLeafHover(false);
	} 
    };

    const secondLeafApex = useRef(stemEndMiddle.current.copy());
    secondLeafApex.current.set(stemEndMiddle.current);
    let secondLeafDirection = stemEndMiddle.current.copy().subtract(stemEnd.current);
    let secondLeafMag = leaf_direction.magnitude();
    secondLeafDirection.set({x: -secondLeafDirection.y, y: secondLeafDirection.x}).multiply(little_leaf_size/secondLeafMag);
    secondLeafApex.current.subtract(secondLeafDirection); 

    const softwareTextPos = useRef(secondLeafApex.current.copy().add({x: -400*props.scaleX, y:0}));
    softwareTextPos.current.set(secondLeafApex.current.copy().add({x: -400*props.scaleX, y:0}));

    const [littleLeafHover, setLittleLeafHover] = useState(() => {return(false)});
    //This stuff needs to happen based on a page hook
    const littleLeafCallback = () => {
	if(props.render)
	{
	    secondTargetApex.current.set({x: stemEndMiddle.current.x - little_leaf_size, y: stemEndMiddle.current.y});
	}
	else
	{
	    secondTargetApex.current.set(secondLeafApex.current.copy());
	    setLittleLeafHover(false);
	}

	let leaf_width = little_leaf_size/4.75 
	let leaf_center_offset = new Zdog.Vector({x: little_leaf_size, y: leaf_width})
	let offset = stemEndMiddle.current.copy().multiply(-1).add(leaf_center_offset)
	props.renderPageCallback(12, offset);
    };

    const onLittleLeafClick = (e, ele) => {
	if(props.render)
	{
	    props.updatePageState("Software", false);
	    littleLeafCallback();
	}
	else
	{
	    props.updatePageState("", false);
	    props.renderPageCallback(1, new Zdog.Vector());
	}
    }

    const onLittleLeafPointerEnter = (e, ele) => {
	if(props.render && !props.isZooming)
	{
	    setLittleLeafHover(true);
	}
    };

    const onLittleLeafPointerExit = (e, ele) => {
	if(props.render && !props.isZooming)
	{
	    //Need to be smarter about turning this off, possibly doing a custom distance check of some kind,
	    //because rotating the leaf with pointer events is a little inconsistent, even if we add a grace period
	    //there are 'dead zones' that appear inside the leaf's rotation hull. Simple offset from that hull
	    setLittleLeafHover(false);
	} 
    };

    const gameTextPos = useRef(stemEndEnd.current.copy().add({x: 150, y:0}));
    gameTextPos.current.set(stemEndEnd.current.copy().add({x: 150, y:0}));

    const [flowerHover, setFlowerHover] = useState(() => {return(false)});

    let flowerDiameter = 100;

    const flowerCallback = () => {
	if(!props.render)
	{
	    setFlowerHover(false);
	}
	
	let flower_center_offset = new Zdog.Vector({x: flowerDiameter, y: flowerDiameter/2})
	let offset = stemEndEnd.current.copy().multiply(-1).add(flower_center_offset)
	props.renderPageCallback(9, offset);
    }

    const onFlowerClick = (e, ele) => {
	if(!props.isZooming) 
	{
	    if(props.render)
	    {
		props.updatePageState("Games", false);
		littleLeafCallback();
	    }
	    else
	    {
		props.updatePageState("", false);
		props.renderPageCallback(1, new Zdog.Vector());
	    }
	}
    }

    const onFlowerPointerEnter = useCallback((e, ele) => {
	if(props.render && !props.isZooming)
	{
	    setFlowerHover(true);
	    console.log("flower interact");
	}
    }, [props.render]);

    const onFlowerPointerExit = useCallback((e, ele) => {
	if(props.render && !props.isZooming)
	{
	    //Need to be smarter about turning this off, possibly doing a custom distance check of some kind,
	    //because rotating the leaf with pointer events is a little inconsistent, even if we add a grace period
	    //there are 'dead zones' that appear inside the leaf's rotation hull. Simple offset from that hull
	    setFlowerHover(false);
	} 
    }, [props.render]);


    const pageUpdater = () => {
	if(props.pageUpdate)
	{
	    if(props.page == "Research")
	    {
		if(props.prevPage == "Research")
		{
		    props.updatePageState("", true);
		    props.renderPageCallback(1, new Zdog.Vector(), "");
		}
		else
		{
		    console.log("remote big leaf");
		    //props.updatePageState("Research", true);
		    bigLeafCallback();
		}
	    }
	    else if(props.page == "Software")
	    {
		if(props.prevPage == "Software")
		{
		    props.updatePageState("", true);
		    props.renderPageCallback(1, new Zdog.Vector(), "");
		}
		else
		{
		    props.updatePageState("Software", true);
		    littleLeafCallback();
		}
	    }
	    else if(props.page == "Games")
	    {
		if(props.prevPage == "Games")
		{
		    props.updatePageState("", true);
		    props.renderPageCallback(1, new Zdog.Vector(), "");
		}
		else
		{
		    props.updatePageState("Games", true);
		    flowerCallback();
		}
	    }
	}
    }
    useEffect(pageUpdater, [props.pageUpdate]) 

    //console.log("render plant");

    let debug = false;
    return(<Anchor>
	    <Root width={stem_width}
	   origin={props.origin}
	   rootPoint={new Zdog.Vector()}
	   rootCP={new Zdog.Vector()}
	   rootEnd={new Zdog.Vector({x: 0, y:100})}/>
	   <Root width={stem_width*0.6}
	    origin={props.origin}
	    rootPoint={new Zdog.Vector({x: 0, y: 5})}
	    rootCP={new Zdog.Vector({x: -50, y: 25})}
	    rootEnd={new Zdog.Vector({x: -50, y: 150})}/>
	   <Root width={stem_width*0.5}
	    origin={props.origin}
	    rootPoint={new Zdog.Vector({x: 0, y: 25})}
	    rootCP={new Zdog.Vector({x: 50, y: 25})}
	    rootEnd={new Zdog.Vector({x: 50, y: 150})}/>

	   <Stem origin={props.origin} stemCP={stemStartCP.current}
	   stemEnd={stemEnd.current} width={stem_width}/>
	    <Leaf leafSize={leaf_size} leafWidthFactor={0.5} page={"Research"}
	    origin={stemEnd.current} restApex={leafApex.current} targetApex={targetApex.current}
	    hover={bigLeafHover}
	    onClick={onBigLeafClick} onPointerMove={onBigLeafPointerEnter}
	    onPointerEnter={onBigLeafPointerEnter}
	    onPointerLeave={onBigLeafPointerExit}/>

	   <Stem origin={stemEnd.current} stemCP={stemMiddleCP.current}
	   bezierCP={stemBezierCP.current} stemEnd={stemEndMiddle.current}
	     bezier={true} width={stem_width*0.9}/>

	   <Leaf leafSize={little_leaf_size} leafWidthFactor={0.5} page={"Software"}
	       origin={stemEndMiddle.current} restApex={secondLeafApex.current} targetApex={secondTargetApex.current}
	     hover={littleLeafHover}
	   onClick={onLittleLeafClick} onPointerMove={onLittleLeafPointerEnter}
	     onPointerEnter={onLittleLeafPointerEnter}
	   onPointerLeave={onLittleLeafPointerExit}/>
	   
	   <Stem origin={stemEndMiddle.current} stemCP={stemEndCP.current}
	   stemEnd={stemEndEnd.current} width={stem_width*0.8}/>

	   <Flower position={stemEndEnd.current} flowerDiameter={flowerDiameter} hover={flowerHover}
	   onClick={onFlowerClick}
	   onPointerMove={onFlowerPointerEnter}
	   onPointerEnter={onFlowerPointerEnter}
	   onPointerLeave={onFlowerPointerExit}/>

	   {/*NOTE: Not sure if this problem is completely solved, but is faster now*/}
	   <ZText text='Research' visible={bigLeafHover && props.render && !props.isScrolling}
	   position={researchTextPos.current} scale={props.scaleX} size={bigLeafHover ? 1 : 0}/>
	   <ZText text='Software' visible={littleLeafHover && props.render && !props.isScrolling}
	   position={softwareTextPos.current} scale={props.scaleY} size={littleLeafHover ? 1 : 0}/>
	   <ZText text='Games' visible={flowerHover && props.render && !props.isScrolling}
	   position={gameTextPos.current} scale={props.scaleX} size={flowerHover ? 1 : 0}/>

	   {debug && 
	    <>
	    <Hemisphere translate={props.origin} diameter={10} stroke={true} color={'#FFFFFF'}/>
	    <Hemisphere translate={stemStartCP.current} diameter={10} stroke={true} color={'#800080'}/>
	    
	    <Hemisphere translate={stemEnd.current} diameter={10} stroke={true} color={'#800080'}/>
	    
	    <Hemisphere translate={stemMiddleCP.current} diameter={10} stroke={true} color={'#808080'}/>
	    <Hemisphere translate={stemBezierCP.current} diameter={10} stroke={true} color={'#808080'}/>
	    <Hemisphere translate={stemEndMiddle.current} diameter={10} stroke={true} color={'#800080'}/>

	    <Hemisphere translate={end_midpoint} diameter={10} stroke={true} color={'#FFA500'}/>
	    <Hemisphere translate={stemEndCP.current} diameter={10} stroke={true} color={'#800080'}/>

	    <Hemisphere translate={stemEndEnd.current} diameter={10} stroke={true} color={'#800080'}/>
	    </>
	   }
	       
	   </Anchor>);
})

const Flower = memo((props) => {

    //useTraceUpdate(props);
    const renderContext = useContext(RenderContext);
        
    var flowerDiameter = props.flowerDiameter;
    var petal_height = props.hover ? flowerDiameter : flowerDiameter  ;
    var petalWidth = useRef();
    petalWidth.current = props.hover ? flowerDiameter : flowerDiameter/2;
    //console.log("render flower");

    const [flowerHeadRotate, setFlowerHeadRotate] = useState(() => {return 0});
    //const flowerHeadRotate = useRef(Zdog.TAU);
    //No render loop for plant right now. Want things a bit more reactive
    //TODO: Adapt behavior from leaves to make sure this can rotate back, and deal with all kind of zooming.
    let delay = 10;
    const plantRender = () => {
	//flowerHeadRotate.current += delay;
	setFlowerHeadRotate(flowerHeadRotate + (delay*0.001));
    }
    useInterval(plantRender, props.hover && (!renderContext.isZooming && renderContext.render) ? delay : null);
    
    return(<Anchor translate={props.position.add(new Zdog.Vector({z:10}))} rotate={{z: flowerHeadRotate}}>
	     <Ellipse diameter={flowerDiameter} color={"#7c4807"} stroke={25} fill={true}
	       translate={{x: 0, z: 10}} rotate={{y: Zdog.TAU/2}}
	   onClick={props.onClick}
	   onPointerMove={props.onPointerMove}
	       onPointerEnter={props.onPointerEnter}
	       onPointerLeave={props.onPointerLeave}/>
	     <Petals petalWidth={petalWidth.current} petalHeight={petal_height} flowerDiameter={flowerDiameter}/>
	   </Anchor>)
})

const Petals = memo((props) => {
    var petalNumber = 12; //Unless we're missing something, react-zdog are just not going to cooperate w/updating the transforms
    //on a lot of different petals.
    var whorl = Zdog.TAU / petalNumber;

    //It's possible it's cheaper to render one path/Shape tag using all this data instead of a ton of individual elements...
    //Same style as leaf arcs and it's no problem... right?
    //It would go: [Base - arcCP - petalTip - arcCP reflected - Base - Jump to NextBase -> etc.]
    
    const petals = [];
    const getPetals = [...Array(petalNumber).keys()].forEach((i) => {
	let petal_axis = new Zdog.Vector({z: whorl * i});
	let petal_margin = new Zdog.Vector({x: 0, y: props.flowerDiameter});
	petal_margin.rotate(petal_axis);

	//NOTE: Since React is so terrible, we need to make two sets of ellipses, which otherwise refuse to update
	//their width/height props. This may be react-zdog being terrible instead, I don't care.

	//Either way, flower performance is very inconsistent even between individual runs of the program...

	//We're probably going to shift to a modified leaf-petal with Bezier sides and animated blooming

	//Adding keys makes this phenomenally slower, for totally unknown reasons.
	petals.push(<Ellipse width={props.petalWidth} height={props.petalHeight}
		    translate={petal_margin} rotate={petal_axis}
		    color='#FFA500' fill={true} stroke={false}/>);

	{/*props.hover && <Shape path={[props.origin,
			 { arc: [ arc1.current,
				  leafApex.current
				]},
			 { arc: [ arc2.current,
				  props.origin
				]}
			]}
		  fill={false} 
		  closed= {false}
		  stroke={1}
		  color={"#000000"}/>*/}
    })
    
    return(<>
	   <Group>
	   {petals}
	   </Group>
	  </>)
})

const Stem = (props) => {
    let curve = { arc: [ props.stemCP, props.stemEnd ]}
    if(props.bezier)
	curve = { bezier: [ props.stemCP, props.bezierCP, props.stemEnd ]};
    return(<Shape path={[props.origin,
			 curve ]}
		  fill={false} 
		  closed= {false}
		  stroke={props.width}
		  color={ "#50C878"}/>);
}

//NOTE: Probably have branch ordering of some kind to organize the root system (and the blog...) and alter color/dimensions.
//Lateral connections/reticulations must be labeled endomycorhizal on some level
const Root = (props) => {
    //Refs appear to be necessary to scale the drawing/make it reactive?
    let origin = useRef(new Zdog.Vector());
    let root_end = useRef(new Zdog.Vector());
    let root_cp = useRef(new Zdog.Vector());
    origin.current.set(props.origin.copy().add(props.rootPoint));
    root_cp.current.set(props.origin.copy().add(props.rootCP));
    root_end.current.set(props.origin.copy().add(props.rootEnd));
    let curve = { arc: [ root_cp.current, root_end.current ]}
    if(props.bezier)
	curve = { bezier: [ props.rootCP, props.bezierCP, props.rootEnd ]};
    return(<Shape path={[origin.current,
			 curve ]}
		  fill={false} 
		  closed= {false}
		  stroke={props.width}
		  color={ "#CCDBC0"}/>);
}

export default Plant
